.calibrate-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* overflow-y: scroll; */
}
span.MuiSwitch-track.css-1yjjitx-MuiSwitch-track {
    background: #4E4E4E;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    opacity: 1;
    border-radius: 50px;
    height:20px;
}

span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
width: 16px;height: 16px;
}
span.MuiSwitch-root .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
    top:5px;
    left: 5px;
}
span.MuiSwitch-root .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #1976d2;
    left: -1.5px;
    top: 5px;
}
span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background-color: #72B9B0;
}

.dashboard__profile__container .MuiButtonBase-root.Mui-selected {
    color: #72B9B0;
}

/* Edit profile */
.edit_profile_content p.textParagraph:first-child {
    line-height: 30px !important;
    letter-spacing: 0em !important;
    color: #535353 !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;
}
.edit_profile_content p.textParagraph:last-child {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px !important;
    color: #000000 !important;
    opacity: 0.6;
    margin-top: 0;
}
.edit__wrapper p {
    margin-left: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.profile__info__container .MuiAvatar-root.MuiAvatar-circular {
    border: 3px solid #C6E3EB;
    border-radius: 50%;
}
.dashboard__profile__container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 10px;
    text-transform: capitalize;
}
.profile__info__container .MuiGrid-root.MuiGrid-item.css-1idn90j-MuiGrid-root button {
    max-width: 215px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.2em;
    height: 50px;
}
.profile__info__container .MuiGrid-root.MuiGrid-item.css-1idn90j-MuiGrid-root button {
    max-width: 215px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.2em;
    height: 50px;
}
.profile__info__container .MuiGrid-root.MuiGrid-item.css-1idn90j-MuiGrid-root{
    padding-top: 60px;
}
.profile__info__container .MuiGrid-root.MuiGrid-item button.MuiButton-containedPrimary{
    color: #fff;
}
.profile__info__container fieldset{
    border: 2px solid #E8E8E8;
    box-shadow: 0px 20px 25px rgba(103, 103, 103, 0.0509804);
    border-radius: 15px;
}
.profile__info__container .MuiFormControl-root.MuiTextField-root {
    border: transparent;
}

.visualization-wrapper {
  transition: transform ease 0.3s;
  cursor: pointer;
 }

.visualization-wrapper:hover {
  transform: scale(1.05);
}

@media (min-width: 0px) and (max-width: 600px) {
    .mob-daily-calibrate-wrapper {
            padding-left: 5px !important;
        }
    .calibrate-wrapper {
        padding-left: 21px;
        padding-top: 20px;
        padding-bottom: 35px;
        padding: 22px;
    }
}
@media (min-width: 600px) {
    .mob-daily-calibrate-wrapper{
        padding-left: 40px !important;
    }
    .calibrate-wrapper {
        padding: 36px;
        padding-top: 20px;
        padding-bottom: 22px;
        /* max-height: 80vh; */
    }
    .sidebar__main__container.sidebar__component_toggle_on {
        width: 100%;
        max-width: max-content;
    }
    .ship__nav .MuiTabs-scroller {
        max-width: 1200px;
        overflow-x: auto !important;
        width: 100%;
    }
    .sidebar__main__container.sidebar__component_toggle_on + #right-content-wrapper {
        width: calc(100% - 100px);
        height: 100vh;
    }
    .sidebar__component_toggle_on .profile__info {
        padding: 0 10px 0 0;
    }
}
@media (min-width: 1024px) {
    .sidebar__main__container {
        width: 100%;
        max-width: 300px;
    }
    #right-content-wrapper {
        width: calC(100% - 0px)
    }
}
@media (max-width: 1024px) {
    .sidebar__main__container {
        width: 100%;
        max-width: 260px;
    }
    #right-content-wrapper {
        width: calC(100% - 0px)
    }
}
