.audio-card, .audio-card-long {
  position: relative;
  /* max-width: 100%; */
  width: 450px;
  height: 250px;
  border-radius: 30px;
  margin-bottom: 13px;
  background-size: cover !important;
  background-position: center !important;
  overflow: hidden;
}

.audio-card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  /* margin-left: 31px;
  margin-bottom: 17px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 35px; */
  color: #ffffff;
  background-color: #355167b7;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.audio-card-playback {
  margin: 7px 12px;
}

.audio-card-playback-timeline {
  margin: 0px 9px;
}

.audio-card-playback-timeline-filled {
  display: inline-block;
  height: 9px;
  background: #355167;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.audio-card-playback-timeline-not-filled {
  display: inline-block;
  height: 9px;
  background: #ffffff;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.audio-card-playback-timers {
  display: flex;
  justify-content: space-between;
}

.audio-card-playback-timer {
  display: inline-block;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 8px;
  padding: 2px 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 600px) {
  .audio-card {
    width: auto;
    min-height: 165px;
    padding: 21px 16px;
  }
  .audio-card-long {
    width: auto;
    min-height: 366px;
    padding: 21px 16px;
  }
  .audio-card-playback {
    margin: 7px 0;
  }
  
  .audio-card-playback-timeline {
    margin: 0px 0px;
  }
}
