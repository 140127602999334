.contact-from-wrapper{
    max-width: 500px;
    min-width: 500px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 15px;
    margin-right: 10px;
}
.input-area{
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    outline: none;
    color: #212121;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}