@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    /* overflow-x: hidden; */
}

.mb10 {
    margin-bottom: 10px;
}

.w100 {
    width: 100%;
}

.wfill {
    width: -webkit-fill-available;
}

.d-flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.jce {
    justify-content: end;
}

.disabled {
    opacity: 0.5;
}