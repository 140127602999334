.image-preview {
    max-width: 250px;
    width: 100%;
    height: 120px;
    border-radius: 10px;
    object-fit: cover;
    border: 2px solid transparent;
}

.image-select {
    border: 2px solid #a73407;
}

.photo-search {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #72b9b0;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 5px;
    outline: none;
    color: #72b9b0;
    max-width: fit-content;
}

.image-checked {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 50;
    color: #a73407;
    background-color: #ffffff83;
    border-radius: 100%;
    width: 24px;
    height: 24px;
}

.deposit-photo-wrapper {
    min-width: 500px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .deposit-photo-wrapper {
        min-width: 100%;
        width: 100%;
    }
}