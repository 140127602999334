.error-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.error-wrapper {
    /* border: 2px solid #5E7689; */
    padding: 15px;
    /* width: 550px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}

.error-wrapper .title {
    color: #5E7689;
    font-weight: 800;
    font-size: 75px;
}

.error-wrapper .subtitle {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    padding-top: 10px;
}

.refresh {
    text-decoration: none;
    padding: 15px;
    background-color: #5E7689;
    color: #fff;
    border-radius: 25px;
    display: block;
    text-align: center;
    margin-top: 65px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .error-wrapper {
        flex-direction: column-reverse;
        text-align: center;
    }

    .error-wrapper .title {
        margin-top: 25px;
    }

    .error-wrapper img {
        width: 250px;
        height: 250px;
        object-fit: contain;
        margin: auto;
    }
}