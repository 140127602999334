:root {
  --font-monteserat: 'Montserrat';
  --primary-form-color: #72B9B0;
  --font-weight-big: 600;
  --font-weight-medium: 400;
}

.form-heading {
  font-family: var(--font-monteserat);
  font-weight: var(--font-weight-big);
  font-size: 18px;
  letter-spacing: 0.2em;
  color: var(--primary-form-color);
  margin-bottom: 0;
}

.form-subheading {
  font-family: var(--font-monteserat);
  font-style: normal;
  font-weight: var(--font-weight-big);
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 0.00925926em;
  margin-top: 0;
}

.form-label {
  font-family: var(--font-monteserat);
  font-weight: var(--font-weight-big);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5em;
  color: #222222;
}

.form-submit-button {
  border: 1px solid var(--primary-form-color) !important;
  border-radius: 29px !important;
  color: #222222 !important;
  padding: 10px 50px !important;
  text-align: center !important;
  margin-top: 60px !important;
}

.drop-area {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #aaa;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
}

.drop-area label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  background: #C6E3EB;
}

.drop-area label p {
  font-family: var(--font-monteserat);
  font-style: normal;
  font-weight: var(--font-weight-big);
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  max-width: 249px;
}

.dragging {
  border-color: #555;
}

.avatar {
  width: 100% !important;
  height: 100% !important;
}

.skip-button {
  padding: 10px 50px !important;
  width: 100% !important;
  text-align: center !important;
  margin-top: 60px !important;
  letter-spacing: 0.2em !important;
  color: #355167 !important;
}

.continue-button {
  border: 1px solid var(--primary-form-color) !important;
  border-radius: 29px !important;
  color: #222222 !important;
  padding: 10px 50px !important;
  text-align: center !important;
  margin-top: 60px !important;
  letter-spacing: 0.2em !important;
}


.dreambig-card {
  background: #132235 !important;
  border-radius: 20px !important;
  color: #FFFFFF !important;
}

.dreambig-price {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: var(--font-weight-big);
  font-size: 36px;
  line-height: 46px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dreambig-price-text {
  margin-top: 0px;
}

.dreambig-hr {
  background: var(--primary-form-color);
  border-radius: 1px;
}

.dreambig-description {
  font-family: Arial;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 16px;
  line-height: 28px;
}

.dreambig-button {
  border: 1px solid var(--primary-form-color) !important;
  ;
  border-radius: 29px !important;
  ;
  padding: 10px 50px !important;
  ;
  width: 100%;
  text-align: center !important;
  margin-top: 10px !important;
  letter-spacing: 0.2em !important;
  color: var(--primary-form-color) !important;
}

.online-community-card {
  background: #FFFFFF;
  border: 2px solid #E8E8E8;
  box-shadow: 0px 0px 25px rgba(103, 103, 103, 0.0509804);
  border-radius: 30px !important;
}

.online-community-heading {
  font-family: var(--font-monteserat);
  font-style: normal;
  font-weight: var(--font-weight-big);
  font-size: 18px;
  line-height: 26px;
  color: #151517;
  max-width: 177px;
  margin: 0 auto;
}

.online-community-hr {
  background: var(--primary-form-color);
  border-radius: 1px;
}

.online-community-description {
  font-family: Arial;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 16px;
  line-height: 28px;
  color: #535353;
  margin: 0 auto;
  max-width: 215px;
}

.online-community-heading p {
  margin-bottom: 0;
  margin-top: 0;
}

.online-community-button {
  padding: 10px 50px !important;
  width: 100% !important;
  ;
  text-align: center !important;
  ;
  margin-top: 10px !important;
  ;
  letter-spacing: 0.2em !important;
  ;
  color: #355167 !important;
  ;
}

.MuiInputBase-root {
  border-radius: 15px !important;
}

.custom-error {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px 14px 0 14px;
}

.dream-journey-text {
  max-width: 494px;
  height: 146px;
  left: 471px;
  top: 472px;
  font-family: Arial;
  font-weight: var(--font-weight-medium);
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  backdrop-filter: blur(5.70647px);
  margin: 0 auto;
}


/* aligning classes*/
.text-center {
  text-align: center;
}

/* Color classes */
.light-red {
  color: #d32f2f;
}

/* Margin classes */
.mt-10 {
  margin-top: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-bottom: 0;
}

.error {
  font-family: var(--font-monteserat);
}

@media only screen and (max-width: 600px) {
  .continue-button {
    margin-top: 0px !important;
  }

  .drop-area {
    width: 250px;
    height: 250px;
  }
}