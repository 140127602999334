.image-wrapper-pdf:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #0d111a85;
}

.image-wrapper-pdf img {
  width: 100%;
  height: 100%;
}

#top-border {
  height: 2px;
  background-color: #2C4B64;
  position: relative;
  top: 5px;
}

#content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  background-color: #fff;
}

#header-logo {
  max-width: 39px;
  object-fit: contain;
}

#header-text {
  text-align: right;
  color: #132235;
  margin-right: 5px;
  font-family: Montserrat;
}

#header-subject {
  font-size: 3px;
  margin-bottom: 0px;
}

#header-user-title {
  font-size: 2.6px;
  color: #132235;
  font-weight: bold;
  margin-top: 2px;
  font-family: Montserrat;
}

#header-user-image {
  width: 9.89px;
  height: 9.89px;
  border-radius: 50%;
}


.title-wrapper {
  padding: 2px 0 2px 13px;
  width: 15px;
  margin: 0px;
  border-top-right-radius: 2px;
  font-style: normal;
  font-size: 3.12px;
  font-family: Montserrat;
}

.ship-content-wrapper {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  width: auto;
  margin: 0;
  padding: 4px 4px 4px 13px;
  max-height: auto;
  height: auto;
  box-sizing: border-box;
  gap: 8px;
}

.image-wrapper-pdf {
  width: 32px;
  height: 20px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 2px;
  overflow: hidden;
  background-position: center center;
}

.ship-item-title-wrapper {
  position: absolute;
  bottom: 0px;
  left: 2px;
  z-index: 999;
}

.ship-item-title {
  font-size: 3px;
  color: white;
  font-family: Montserrat;
}

.ship-manifest {
  font-size: 3px;
  margin: 1px;
  font-family: Montserrat;
}

.popup-download {
  position: fixed;
  width: 100%;
  height: 100%;
  background: whitesmoke;
  left: 0;
  top: 0px;
  max-width: 582px;
  border-top-right-radius: 40px;
  z-index: 999 !important;
  overflow-y: auto;
}

.popup-content {
  position: relative;
  padding-top: 80px;
}

.edit-ship__close-btn {
  position: absolute;
  right: 50px;

}



.popup-title {
  display: flex;
  gap: 20px;
}

.header-main__title {
  font-family: Montserrat;
   font-size: 30px;
  font-weight: 600;
   line-height: 64px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #5E7689;
  border-right: 3px solid black;
  padding: 0px 20px 0 50px;
}

.header-sub__title {
  font-family: Montserrat;
   font-size: 20px;
  font-weight: 500;
   line-height: 64px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #5E7689;
}

.popup-body {
  margin: 30px auto;
  width: 85%;
}

.body-content__png {
  width: 100%;
  background: #FFF;
}


.body-content__title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #758C9E;
  padding-bottom: 5px;
  border-bottom: 1px solid #C0C9DE;
}

.popup-body__download-btn{
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  letter-spacing: 0.2em !important;
  text-align: center !important;
  padding: 15px 30px !important;
  border-radius: 25px !important;
  margin: 5px 0px 5px 15px !important;
  min-width: 220px !important;
}

.png-width-preview{
  max-width: 30px;
  word-wrap: break-word
}
