.profile_page_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 135px;
}

.profile__info__container {
    /* padding: 32px 125px; */
    max-width: 800px;
    width: 100%;
}

.dashboard__profile__component {
    display: flex;
    align-items: start;
}

.edit__wrapper {
    display: flex;
    align-items: center;
    max-width: 77px;
    padding: 10px;
    /* justify-content: center; */
}

.edit__wrapper p {
    margin-left: 10px;
    font-size: 18px;
}

.edit__wrapper:hover {
    cursor: pointer;
}

.cancel__btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    border-radius: 29px;
    margin-right: 30px;
    border: 1px solid #AC3131;
    text-align: center;
    letter-spacing: 0.2em;
    color: #0D111A;
    padding: 10px 23px;
}

.dashboard__profile__container {
    padding: 32px;
}

@media only screen and (max-width: 600px) {
    .dashboard__profile__container {
        padding: 15px;
    }
}