.background {
    background: url(../../assets/images/login_background.png);
    position: absolute;
    max-width: 100%;
    height: 624px;
    display: flex;
    align-items: center;
    top: 0;
    width: 100%;
    background-position: top;
    background-size: cover;
    z-index: -1;
    background-repeat: no-repeat;
    left: 0
}

.containerStyle {
    margin: 210px auto 120px auto;
    width: 100%;
    max-width: 750px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 65px;
    padding: 45px;
    z-index: 1
}

.logo {
    width: 293px;
    height: 36px;
    margin: 30px 50px 0px
}

@media only screen and (max-width: 600px) {
    .containerStyle {
        border-radius: 25px;
        margin: 150px 5px 0 5px;
        width: auto;
        max-width: auto;
        padding: 15px 25px;
    }

    .logo {
        margin: 15px auto 0 auto;
    }
}