.sidebar__component {
    background: #5E7689;
    border-radius: 0px 60px 0px 0px;
    justify-content: space-between;
    align-items: center;
    line-height: 0.5;
    padding: 20px;
    height: 100vh;
    min-width: 300px;
    max-width: 300px;
    /* visibility: visible; */
    /* opacity: 1; */
    /* transition: ease-in-out all 0.2s; */
    position: fixed;
    z-index: 99;
    top: 100px;
    left: 0;
    width: 100%;
}

a.sidebar__navitem {
    color: #ffffff;
}

a.sidebar__navitem:hover {
    cursor: pointer;
}

.sidebar__navitem.active {
    background: #C6E3EB;
    border-radius: 10px;
}

.sidebar__navitem.inactive__nav-item {
    opacity: 0.5;
}

.sidebar__navitem.active p {
    color: #000000;
}

.sidebar__component_toggle {
    position: fixed;
    z-index: 99;
    top: 100px;
    left: 0;
    background: #5E7689;
    height: 100vh;
    border-radius: 0px 25px 0px 0px;

}

.sidebar__component_toggle ul.nav__items p.textParagraph {
    display: none;
}

.sidebar__component_toggle ul.nav__items li a {
    align-items: center;
    justify-content: center;
    padding: 0;
}

.sidebar__main__container {
    width: auto !important;
    /* transition: all ease-in-out 0.8s; */

}

.sidebar__main__container .sidebar__component_toggle {
    max-width: 120px;
    padding: 12px;
    width: 100%;
}

.sidebar__component_toggle .profile__component {
    display: block;
}

.profile__component {
    display: flex;
    align-items: flex-start;

}

.profile__info {
    word-break: break-word;
    color: #ffffff;
    padding: 1px 14px;

}

.profile__info h2 {
    font-size: 18px;
}

.profile__info p {
    color: #C6E3EB;
    font-size: 10px;
}

.sideitems__container ul {
    list-style-type: none;
    padding: 0;
}

.sidebar__main__container {
    /*margin-top: 11px;*/
    background: #5E7689;
    border-radius: 0px 60px 0px 0px;
    position: fixed !important;
    z-index: 99;
    height: 100vh;
}

ul.nav__items li a {
    display: flex;
    align-items: center;
    width: auto;
    height: 42px;
    margin-top: 2px;
    padding-left: 16px;
}

ul.nav__items li a:active {
    background: #C6E3EB;
    border-radius: 10px;
}

ul.nav__items li a {
    display: flex;
    align-items: center;
}

ul.nav__items li a p {
    margin-left: 20px;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 10.5px;
}

.profile__info h4 {
    /* margin: 17px 0px; */
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px !important;
}

.profile__info p.textEyebrow {
    line-height: 20px;
}

a.sidebar__navitem span {
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mob_close {
    display: none;
}

@media only screen and (max-width: 600px) {
    .sidebar__main__container{
        z-index: 99999;
    }
    .mob-sidebar-help{
        display: none !important;
    }
    .sidebar__component_toggle {
        opacity: 0;
        visibility: hidden;
    }

    .mob_email {
        display: none;
    }

    .sidebar__component {
        /* transition: all ease-in-out 0.8s; */
        top: 0;
        width: 100%;
        min-width: 180px;
        max-width: 180px;
    }

    .profile__component {
        /* justify-content: space-between; */
        align-items: center;
    }

    .mob_close {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #415669;
        color: #fff;
        border: none;
    }

    .sidebar__component_toggle {
        left: -250px !important;
        position: absolute !important;
        /* transition: all ease-in-out 0.8s; */
        visibility: hidden;
        min-width: 0px;
        max-width: 0px;
        /* padding: 0; */
        overflow: hidden;
    }
}
