.individual__tabs {
    min-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    margin: 10px;
    color: #ffffff;
    border-radius: 10px;
    max-height: 102px;
}

.individual__tabs.mid .individual__tabs_inner {
    -moz-transform: skew(-23deg, 0deg);
    -webkit-transform: skew(-23deg, 0deg);
    -o-transform: skew(-23deg, 0deg);
    -ms-transform: skew(-23deg, 0deg);
    transform: skew(-23deg, 0deg);
}

.individual__tabs.first {
    background: linear-gradient(283.43deg, rgba(19, 34, 53, 0.2) -0.39%, rgba(19, 34, 53, 0) 61.74%), linear-gradient(0deg, #E5F2F6, #E5F2F6);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 10px 50px;
    /* transform: skew(23deg, 0deg); */
}

.individual__tabs.Wealth-wrap {
    background: linear-gradient(279.9deg, rgba(19, 34, 53, 0.34) -1.15%, rgba(19, 34, 53, 0) 77.83%), #C6E3EB;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    /* -moz-transform: skew(23deg, 0deg);
    -webkit-transform: skew(23deg, 0deg);
    -o-transform: skew(23deg, 0deg);
    -ms-transform: skew(23deg, 0deg);
    transform: skew(23deg, 0deg); */
}

.individual__tabs.Play-wrap {
    background: linear-gradient(279.94deg, rgba(19, 34, 53, 0.5) -1.01%, rgba(19, 34, 53, 0) 76.38%),
        linear-gradient(0deg, #5E7689, #5E7689);

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    /* -moz-transform: skew(23deg, 0deg);
    -webkit-transform: skew(23deg, 0deg);
    -o-transform: skew(23deg, 0deg);
    -ms-transform: skew(23deg, 0deg);
    transform: skew(23deg, 0deg); */
}

.individual__tabs.Passion-wrap {
    background: linear-gradient(284.32deg, #132235 -2.5%, rgba(19, 34, 53, 0) 101.28%),
        linear-gradient(0deg, #355167, #355167);

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    /* -moz-transform: skew(23deg, 0deg);
    -webkit-transform: skew(23deg, 0deg);
    -o-transform: skew(23deg, 0deg);
    -ms-transform: skew(23deg, 0deg);
    transform: skew(23deg, 0deg); */
}

.individual__tabs.Wealth-wrap {
    background: #132235;
    border: 1px solid #000000;
    color: #ffffff;
    /* transform: skew(23deg, 0deg); */
    border-radius: 10px 41px 113px 10px;
    box-shadow: 0px 4px 10px 0px #0000001F;
    position: relative;
}

.individual__tabs.first.Wealth-wrap:after {
    content: '';
    background: #132235;
    width: 55px;
    height: 101px;
    position: absolute;
    transform: skew(-22deg, 0deg);
    border-radius: 0px 44px 20px 0px;
    right: 0px;
    border: 1px solid transparent;
    border-left-color: transparent;
}

.ship__nav button.MuiButtonBase-root.Mui-selected .individual__tabs.Wealth-wrap:after {
    height: 101px;
}

.ship__nav button.MuiButtonBase-root.Mui-selected .individual__tabs.Wealth-wrap:after {
    content: '';
    border: 1px solid #72B9B0;
    border-left-color: transparent;
}

.individual__tabs.first.Health-wrap:after {
    content: '';
    background: #E5F2F6;
    width: 55px;
    height: 102px;
    position: absolute;
    transform: skew(-22deg, 0deg);
    border-radius: 10px 0px 0px 40px;
    left: 0;
    border: 1px solid transparent;
    border-right: 0;
}

.ship__nav button.MuiButtonBase-root.Mui-selected .individual__tabs.Health-wrap:after {
    content: '';
    border: 1px solid #72B9B0;
    border-right: 0;
}

.individual__tabs.first.Health-wrap {
    position: relative;
    border-radius: 80px 10px 10px 44px;
}

.individual__tabs_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    margin: 10px;
    transform: skew(-23deg, 0deg);
    z-index: 1;
}

.tab__main__container {
    display: flex;
    width: 100%;
    /* margin-top: 5%; */
    padding-left: 50px;
}

.individual__tabs_inner p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5em;
    color: #fff;
}

.ships__topbanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
}

.ships__topbanner_right {
    display: flex;
    max-width: 400px;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.ship__nav {
    padding: 0px 40px;
    margin-bottom: 16px;
}

.ships__topbanner_right p:first-child {
    background: #5E7689;
    border-radius: 5px;
    color: #ffffff;
    padding: 0px 10px;
    margin: 0;
    cursor: pointer;
}

.ships__topbanner_right a {
    text-decoration: none;
}

.ships__topbanner_right .viewall-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5em;
    color: #222222;
}

.ship__nav button.MuiButtonBase-root {
    -moz-transform: skew(23deg, 0deg);
    -webkit-transform: skew(23deg, 0deg);
    -o-transform: skew(23deg, 0deg);
    -ms-transform: skew(23deg, 0deg);
    transform: skew(23deg, 0deg);
    padding: 0;
}

.individual__tabs.Play-wrap,
.individual__tabs.Self-wrap,
.individual__tabs.Passion-wrap {
    border-radius: 10px;
}


.individual__tabs.Health-wrap p {
    color: #222222;
}

.individual__tabs.Wealth-wrap p {
    color: #ffffff;
}

.individual__tabs.Self-wrap p {
    color: #222222;
}

.right__container a p {
    color: #222222;
}

.left__container h3.texth3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: #000000;
    margin: 0;
}

.ship__nav button.MuiButtonBase-root:first-child {
    padding-left: 15px;
}

.ship__nav button.MuiButtonBase-root:last-child {
    padding-right: 15px;
}

.ship__nav span.MuiTabs-indicator {
    display: none !important;
}

.ship__nav button.MuiButtonBase-root.Mui-selected .individual__tabs {
    border: 1px solid #72B9B0;
}

.ship__nav button.MuiButtonBase-root .individual__tabs {
    border: 1px solid transparent;
}

.ship__nav ::-webkit-scrollbar {
    height: 8px;
}

/* Track */
.ship__nav ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.ship__nav ::-webkit-scrollbar-thumb {
    background: #5E7689;
    border-radius: 10px;
}

.view-all-btn {
    display: flex;
    align-items: center;
    align-content: center;
}

.ships__topbanner_right.second {
    justify-content: end;
    padding-right: 25px;
}

@media only screen and (max-width: 600px) {
    .ships__topbanner {
        display: block;
        padding: 0 15px;
    }

    .ships__topbanner .texth2 {
        line-height: normal;
    }

    .ships__topbanner_right.second {
        justify-content: start;
    }

    .ships__topbanner_right {
        justify-content: space-between;
    }

    .ships__topbanner_right.second {
        display: block;
        text-align: center;
        padding-right: 0;

    }

    .ships__topbanner_right.second a p {
        padding: 5px 0;
        display: block;

    }

    .ship__nav {
        padding: 0;
        max-width: 400px;
        width: 100%;
        overflow-x: scroll !important;

    }

    .ship__nav .MuiTabs-flexContainer {

        overflow-x: scroll !important;

    }

    .ship__nav .MuiTabs-flexContainer::-webkit-scrollbar-track {
        background: #fff;
        border: none;
        box-shadow: none;
    }

    .ship__nav .MuiTabs-flexContainer::-webkit-scrollbar {
        width: 0;
        background-color: #fff;
    }

    .ship__nav .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
        background: #fff;
        border: none;
    }

    .individual__tabs.first.Wealth-wrap:after {
        display: none;
    }

    .individual__tabs {
        padding: 0;
    }

    .individual__tabs.first.Health-wrap:after {
        display: none !important;
    }

    .textParagraph {
        font-size: 12px !important;
    }

    .ship__container {
        padding: 10px !important;
    }

    .ship__container .customStyle {
        display: block;
    }


    /* .individual__tabs.first {
        padding: 0;
        height: auto;
        width: auto;
        overflow: auto;
    }

    .individual__tabs_inner {
        margin: 0;
    }

    .individual__tabs.first::after {
        display: none;
    }

    .individual__tabs.first::before {
        display: none;
    } */
}