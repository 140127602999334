.input-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.input {
    background: #ffffff;
    outline: #e8e8e8 auto 2px;
    box-shadow: 0px 20px 25px rgba(103, 103, 103, 0.0509804);
    border-radius: 15px;
    padding: 15px 30px;
}
.input:focus-visible {
    outline: #aaa auto 2px;
}
.input, .input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    color: #676767;
    font-family: 'Arial';
    opacity: 0.6;
}
.disabled {
    opacity: 0.5;
}
