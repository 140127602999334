.customStyle {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* gap: 15px; */
}

.ship__container {
  background: #D2E4E978;
  padding: 60px 40px;
  margin-top: 5%;
  width: auto;

}

.css-1pg0exv-MuiContainer-root {
  padding-left: 0px;
  padding-right: 0px;
}

@media only screen and (max-width: 600px) {
  .ship__container {
    /* padding: 10px; */
    max-width: 100%;
    overflow: hidden;
  }


}