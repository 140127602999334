.audio-library-container{
    display: flex;
    align-items: center;
    max-width: 750px;
    width: 100%;
}
#waveform{
    min-width: 200px;
}
.audio-library-individual {
    display: flex;
    align-items: center;
    /* padding: 20px; */
    justify-content: space-between;
}
.audio-library-inner, .upload-audio__container {
    max-height: 261px;
    max-width: 487px;
    background: #353F53B8;
    overflow-y: auto;
    /* border: 1.14px solid #FFFFFF40; */
    border-radius: 0px 10px 10px 10px;
    text-align: center;
    min-height: 261px;
    
}
.upload-audio__container{
  /* padding: 19px; */
  display: flex;
  flex-direction: column;
}

.upload-audio__container img{
  max-width:  404px;
  height: auto;
  margin: auto;
}


.p0{
    padding: 0;
} 
.m0{
    margin: 0;
}
.audio-library-inner::-webkit-scrollbar {
    width: 10px;
    
  }
  .audio-library-inner .audio-recorder {
    padding: 1rem;
    min-height: 30px;
}
  
  /* Track */
 ::-webkit-scrollbar-track {
    background: #353F53B8; 
    border-radius: 6px;
  }
   
  /* Handle */
  .audio-library-inner::-webkit-scrollbar-thumb {
    background: #5E7689; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  .audio-library-inner::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .audio__tabs .Mui-selected{
    color:#ffffff;
  }
  .audio__title{
    color: #ffffff;
    font-size: 14px;
    margin-left: 3px !important;
    max-width: 56px;
    word-wrap: break-word;

  }
  .upload-audio__btn{
    margin-bottom: 25px
  }
