.edit-ship-audio-container {
  /* gap: 44px; */
}

.edit-ship__dialog {
  background: rgba(0, 0, 0, 0.71);
  backdrop-filter: blur(20px);
}

.edit-ship__close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
}

.edit-ship__close-btn:hover {
  cursor: pointer;
}

.edit-ship-photo-container {
  gap: 12px;
}

.edit-ship__wrapper {
  background: white;
  padding: 43px 56px 34px 48px;
}

.edit-ship-wrapper::-webkit-scrollbar {
  display: none;
}

.edit-ship-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 39px;
}

.edit-ship-drop-area {
  border-radius: 30px;
  max-height: 176px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #aaa;
  background: #c6e3eb;
  cursor: pointer;
}

.edit-ship-avatar {
  background-size: contain;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  height: 176px;
  width: 434px;
}

.edit-ship-drop-area label p {
  font-family: var(--font-monteserat);
  font-style: normal;
  font-weight: var(--font-weight-big);
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  max-width: 249px;
  cursor: pointer;
  margin: 55px auto;
}

.edit-ship-form-container {
  display: flex;
  flex-direction: column;
  gap: 29px;
  /* margin-bottom: 89px; */
}

.edit-ship-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.edit-ship__disabled {
  opacity: 0.5;
}

.edit-ship__save-button {
  background: #72b9b0 !important;
  border: 1px solid #72b9b0 !important;
  border-radius: 29px !important;
  color: white !important;
  padding: 15px 80px !important;
  max-height: 57px;
}

.edit-ship-drop-area label {
  width: 100%;
  height: 178px;
}

.deleteBtn {
  font-family: 'Montserrat';
  font-style: 'normal';
  font-weight: '600';
  border-radius: '29px';
  margin-right: '30px';
  border: '1px solid #AC3131';
  text-align: 'center';
  letter-spacing: '0.2em';
  color: '#0D111A';
  padding: '10px 23px';
}

@media only screen and (max-width: 600px) {
  .edit-ship__dialog {
    width: 100%;
  }

  .edit-ship__wrapper {
    padding: 20px;
    width: auto;
  }

  .edit-ship__wrapper {
    display: block;
  }

  .audio-recorder__buttons {
    /* display: block !important; */
    flex-wrap: wrap !important;
    flex-basis: 50%;
  }

  .edit-ship-avatar {
    width: 100%;
  }

  .edit-ship-header {
    display: block;
  }
}
