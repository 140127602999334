.calibrate-card__item {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.calibrate-card__item:hover{
  transform: scale(1.05);
}

.calibrate-card__bottom {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.4);
}

.calibrate-card__bottom p {
  color: white !important;
  padding: 10px;
}

.calibrate-card__content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
}

@media (min-width: 0px) and (max-width: 600px) {
    .calibrate-card__item {
        width: auto;
        min-height: 150px;
        padding: 21px 16px;
    }

    .calibrate-wrapper {
        width: auto;
    }
}

@media (min-width: 600px) {
    .calibrate-card__item {
        width: 100%;
        max-width: 857px;
        min-height: 286px;
    }

    .calibrate-item__content {
        width: 100%;
        padding: 20px 30px;
        border-radius: 8px;
    }
}
