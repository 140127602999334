.breadcrumb-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 10px;
  color: #355167;
}

.page-title-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.page-subtitle-text {
  margin-bottom: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5em;
  color: #222222;
}
.ship-manifests__wrapper {
  /* max-height: 90vh;

  overflow-y: scroll; */
  padding-left: 35px;
}
.ship-manifests__wrapper::-webkit-scrollbar {
  display: none;
}
.ship-manifests__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 0px) and (max-width: 600px) {
  .mob-rev{
    flex-direction: column-reverse !important;

  }
  .ship-manifests__header {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 23px;
    gap: 43px;
  }
  .ship-manifests__wrapper {
    width: 100%;
    padding-left: 0;
  }
}
@media (min-width: 600px) {
  .ship-manifests__header {
    display: flex;
    flex-direction: row;
  }
}