.container {
  marginTop: '200px'
}

.footer__container {
  background: #D3DADF;
  backdrop-filter: blur(5px);
  position: relative;
  padding: 0 60px;
}

.footer__left p {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #FFFFFF;
  max-width: 367px;
}

.footer__nav h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.2em;
  color: #72B9B0;
}

.copyright__container {
  display: block;
  height: 400px;
  position: relative;
  width: 100%;
}

.copyright__container p {
  position: absolute;
  bottom: 25px;
  left: 0px;
}

.copyright__container>p {
  color: #8D8D8D;
  opacity: 1;
}

.footer__component {
  position: absolute;
  width: calc(100% - 100px);
  top: -100px;
  left: 50%;
  /* margin: 0 50px; */
  background: #132235;
  border-radius: 30px;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  transform: translate(-50%, 0%);
  z-index: 1;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
  padding: 60px;
}

.footer__nav>ul {
  list-style-type: none;
  padding: 0;
}

.footer__nav>ul>li>a {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
}

.footer__socialIcon ul {
  display: flex;
  list-style-type: none;
  margin-bottom: 30px;
}

.footer__lower {
  width: 100%;
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  align-items: flex-end;
}

.footer__lower .footer__socialIcon li:nth-child(2) {
  margin: 0 30px;
}


@media only screen and (max-width: 600px) {
  .container {
    margin-top: 150px;
  }

  .footer__component {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .footer__inner {
    display: block;
    padding: 20px;
  }

  .footer_logo {
    margin: auto;
    text-align: center;
  }

  .footer__nav {
    text-align: center;
  }
}