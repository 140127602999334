.renew-btn {
    background-color: #72B9B0;
    padding: 20px 30px;
    color: #fff;
    letter-spacing: 4px;
    font-size: 18px;
    border: none;
    border-radius: 25px;
    font-weight: 700;
    cursor: pointer;
}