.cardoverlay{
    position: relative;
}
.editbtn{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2222;
}
.tickIcon{
    max-width:164.2px ;
    min-width: 130.2px;
    position: absolute;
    top: calc( 40% - 30px );
    left: calc( 50% - 60px );
}
.viewItems{
    max-width:164.2px;
    min-width: 130.2px;
    position: absolute;
    top: calc( 80% - 80px );
    left: calc( 50% - 60px );    
}
.editbtn:hover {
    cursor: pointer;
}
.action__items {
    opacity: 0;
}
.cardoverlay:hover .action__items {
    opacity: 1;
}

.cardoverlay:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #00000040;
    position: absolute;
    border-radius: 40px;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
}

.cardoverlay:hover:before {
    opacity: 1;
}
.tickIcon:hover, .viewItems:hover{
    cursor: pointer;
}
.tickIcon, .viewItems{
    z-index: 2;
}
.tickIcon .btn, .viewItems .btn{
    border: none;
    height: 45px;
    width: 100%;
    left: 47.89453125px;
    top: 97px;
    border-radius: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    z-index: 99;
    cursor: pointer;
}

.tickIcon .btn img, .viewItems .btn img{
    margin-right: 10px
}

.cardoverlay:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 40px;
    z-index: 1;
    top: 0;
    left: 0;   
}
.cardoverlay.sea:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.47) 77.29%);
}

.cardoverlay.arrived:after {
    background: linear-gradient(149.56deg, rgba(114, 185, 176, 0.8) -0.9%, rgba(19, 34, 53, 0.8) 90.35%);
}
.cardoverlay h3.texth3 {
    z-index: 2;
    margin: 10px 0px;
    padding: 0;
}

.ship-arrived-container.tickIcon{
    position: absolute;
    top: calc( 40% - 0px ) !important;
    left: calc( 50% - 84px ) !important;
}

.tickIcon.ship-arrived-container{
    display: none;
}
.tickIcon.ship-arrived-container{
    display: none;
}
.cardoverlay.arrived:hover .tickIcon.ship-arrived-container{
    display: block;
}