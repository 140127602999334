.account__wrapper.edit-btn {
    display: flex;
}

.account__wrapper.edit-btn img {
    margin-right: 15px;
}

.cancel-btn {
    padding: 15px 25px;
    border-radius: 25px;
    border: 1px solid rgb(230, 49, 49);
    margin-right: 10px;
    background-color: #fff;
    color: rgb(230, 49, 49);
    font-weight: 700;
    letter-spacing: 4px;
}

.cancel-btn:hover {
    background-color: rgb(230, 49, 49);
    color: #fff;
}