.isOpen {
  padding-left: 350px;
  transition: ease-in-out all 0.5s
    /* width: calc(100% - 250px); */
}

.main-wrapper {
  /* display: flex; */
}

.sidebar__main__container,
.sidebar__component,
.sidebar__component_toggle {
  position: unset;
}

.isClose {
  /* width: calc(100% - 144px); */
  padding-left: 140px;
  transition: ease-in-out all 0.5s
}

.noMargin {
  margin-left: 0px;
}

@media only screen and (max-width: 600px) {

  .isClose,
  .isOpen {
    margin-left: 0px;
    /* margin-top: ; */

    padding-left: 0px;
  }
}