.texth1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    /* line-height: 55px; */
    letter-spacing: 0.00925926em;
    backdrop-filter: blur(5px);
    color: #132235;
    padding-bottom: 34px;
}

.texth2 {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0.0093em;
    color: #132235;
}

.texth3 {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0.0093em;
    color: #132235;
}

.texth4 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    color: #000000;
}

.textParagraph {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #535353;
}

.textSmallParagraph {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
}

.textEyebrow {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.2778em;
    /*text-align: left;*/
    color: #72B9B0;
}

.textLabel {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5em;
    color: #222222;
    text-transform: uppercase;
    margin: 0 0 12px;
}

div.css-honmcm-MuiGrid-root>.MuiGrid-item {
    padding-top: 36px;
}


@media only screen and (max-width: 600px) {
    .texth2 {
        font-size: 20px;
    }

    .texth1 {
        font-size: 24px;
    }

    .texth3 {
        font-size: 14px !important;
    }
}