.audio-recorder {
  padding: 1rem;
  min-height: 65px;
  display: flex;
  align-items: center;
}

.audio-recorder__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #355167;
}
.audio-recorder__text.list {
  color: #fefefe;
  font-size: 14px;
}

.audio-recorder__text-no-audio {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #355167;
    position: absolute;
    height: 100%;
    align-items: center;
    margin-left: 0.5rem;
  }
  #wavesurferId{
    /* display: none; */
    width: 120px !important;
  }