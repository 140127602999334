.custom-btn {
    padding: 15px 30px;
    border-radius: 25px;
    border: none;
    font-size: 14px;
    letter-spacing: 10px;
    text-align: center;
    margin-left: 25px;

}
.custom-btn.sm{
    padding: 10px 20px;
    letter-spacing: 1px;
}

.custom-btn.lg{
    padding: 20px 30px;
}
.custom-btn:hover{
    cursor: pointer;
}

.custom-btn__important {
  background: #FFFFFF !important;
  color: #000000 !important;
  padding: 10px 25px !important;
  border-radius: 25px !important;
  border: none !important;
  font-size: 14px !important;
  letter-spacing: 10px !important;
  text-align: center !important;
  margin-left: 25px !important;
}
