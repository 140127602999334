.drop-container {
    position: relative;
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
    background: #eee;
    border-color: #111;
}

.drop-container:hover .drop-title {
    color: #222;
}

.drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: #0d45a5;
}

.drop-photo-prev {
    position: absolute;
    top: 10%;
    right: 0;
    width: 100px;
    height: 100px;
    border: 2px dashed #ddd;
    border-radius: 5px;
    overflow: hidden;
    padding: 2px;
}